.job-details-hero {
    background-color: var(--white-col);
    height: 50vh;
}

.job-details-hero .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    background-color: var(--gray-col);
}

.job-details-hero .container h1 {
    font-size: 2rem;
    color: var(--blue-col);
}

.job-details-main {
    background-color: var(--white-col);
}

.job-details-main .container {
    background-color: var(--white-col);
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    gap: 1rem;
}

.job-details-main .container .top {
    display: grid;
    grid-template-columns: 40% 40% 20%;
}

.job-details-main .container .top .left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
    flex-direction: column;
}

.job-details-main .container .top .left .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.6rem;
}

.job-details-main .container .top .left .skills p {
    background-color: rgb(235, 233, 233);
    padding: 0.2rem 0.7rem;
    border-radius: 20px;
}

.job-details-main .container .middle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 0.4rem;
}

.job-details-main .container .middle p span {
    font-weight: bold;
}



.job-details-main .container .top .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}

.job-details-main .container .top .right img {
    width: 70px;
}

.job-details-main h1.head-font {
    color: var(--blue-col);
}


.job-details-main .container .buttons {
    display: flex;
    gap: 0.5rem;
}

.job-details-main .container .content {
    margin-top: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 35% 35%;
    gap: 1rem;
}

.job-details-main .container .content h1 {
    font-size: 1.3rem;
}

.job-details-main .container .content p {
    line-height: 25px;
}

.job-details-main .container .employement-type {
    padding: 0.4rem 1rem;
    background-color: var(--blue-col);
    color: var(--white-col);
}

.job-details-main .container .content .benefits {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.job-details-main .container .content .outline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    text-align: justify;
}

.job-details-main .container .content .future-growth {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    text-align: justify;
}

.job-details .apply-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: -1;
}

.job-details .apply-box.show-apply-box {
    display: flex;
    z-index: 1;
}

.job-details .apply-box .box {
    width: 400px;
    height: 200px;
    background-color: var(--white-col);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.job-details .apply-box .box label {
    border: 1px solid rgb(194, 194, 194);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--blue-col);
}

.job-details .apply-box .box label .upload-icon {
    font-size: 2rem;
}

.job-details .apply-box .box input[type=file] {
    visibility: hidden;
    display: none;
}

.job-details .apply-box .box .buttons {
    display: flex;
    gap: 0.4rem;
}

@media screen and (max-width: 950px) {
    .job-details-main .container .top {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .job-details-main .container .content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
    }

    .job-details-main .container .content .benefits,
    .job-details-main .container .content .outline,
    .job-details-main .container .content.future-growth {
        width: 100%;
    }
}