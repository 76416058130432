body {
    width: 100%;
    background-color: var(--gray-col);
    color: var(--black-col);
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    overflow-x: hidden;
}

.container {
    width: 85%;
    margin: auto;
}
