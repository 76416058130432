.quizmodules {
    background-color: var(--gray-col);
}

.quizmodules .hero {
    width: 100%;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--white-col);
    box-shadow: 0 0 40px gray;
    margin-bottom: 2.5rem;
}

.quizmodules .hero .head-font {
    font-size: 2rem;
}

.quizmodules .hero .head-font span {
    color: var(--blue-col);
}

.quizmodules .hero p {
    letter-spacing: 2px;
    margin-bottom: 1rem;
}

.quizmodules .hero .search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.quizmodules .hero .search input {
    padding: 0.4rem 1rem;
    width: 60%;
    border: 1px solid rgb(201, 200, 200);
}

.quizmodules .container .modules {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 0;
    background-color: var(--gray-col);
}

.quizmodules .container .modules .quiz-test.hide {
    display: none;
}

.quizmodules .container .modules .modules-container {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.quizmodules .container .modules .modules-container.hide {
    display: none;
}

.quizmodules .container .modules .modules-container .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border: 1px solid var(--gray-col);
    gap: 1rem;
    width: 300px;
    height: 150px;
    background-color: var(--white-col);
    border-radius: 30px 40px 50px 20px;
    overflow: hidden;
}
.quizmodules .container .modules .modules-container .item .header{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem
}
.quizmodules .container .modules .modules-container .item .header h1{
    margin-bottom: 2.5rem;
}
.quizmodules .container .modules .modules-container .item .header img{
    width: 40px;
    display: block;
}

.quizmodules .container .modules .modules-container .item .head-font {
    margin-top: 2rem;
}

.quizmodules .container .modules .modules-container .item .blue-btn {
    width: 100%;
}