.container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 0;
  }
  
  .heading {
    font-weight: 500;
    font-size: 2.1rem;
  }
  
  .toolbar {
    width: 100%;
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
  }
  
  .colors {
    display: flex;
    gap: 20px;
    padding: 0 30px;
  }
  
  .colors .color {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #239ce2;
  }
  .colors .active {
    border: 2px solid #000;
  }
  
  .toolbar button {
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #239ce2;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }
  
  .toolbar button svg {
    height: 20px;
    width: 20px;
  }
  
  .main {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }
  .previewHeader{
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 1.6rem;
    padding: 1rem 0;
  }

  @media screen and (max-width:800px) {
    .container{
      width: 400px;
      padding-left: 0px;
    }
    .container .main{
      overflow: scroll;
      margin-left: 0px;
    }
    .toolbar{
      display: flex;
      flex-direction: column;
    }
  }