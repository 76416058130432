.quiz-main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--white-col);
  z-index: 2;
  display: flex;
  align-content: center;
  justify-content: center;
  background-image: url('../../../assets/quiz-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.quiz-main-container .quiz-wrapper {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quiz-main-container .quiz-wrapper .quiz-container {
  background-color: var(--white-col);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.quiz-main-container .quiz-wrapper .quiz-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #239ce2;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: rotateZ(5deg);
  border-radius: 10px;
  z-index: -1;
}

.quiz-main-container .quiz-wrapper .quiz-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05517d;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transform: rotateZ(-5deg);
  border-radius: 10px;
  z-index: -1;
}

.quiz-main-container .heading {
  color: var(--black-col);
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
}

.quiz-main-container .question {
  margin: 8px;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-size: large;
  font-weight: 600;
  height: 15vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-main-container #question-number {
  margin-right: 5px;
}

.quiz-main-container .option-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: 100%;
  padding: 10px;
}

.quiz-main-container .option-btn {
  padding: 5px;
  border-radius: 3px;
  border: none;
  outline: none;
  transition: 0.3s;
  margin: 5px;
  height: 3rem;
  width: 100%;
  background-color: white;
  font-size: 1.1rem;
  border-radius: 30px;
  border: 2px solid var(--blue-col);
}

.quiz-main-container .checked {
  background-color: var(--blue-col);
  color: white;
  box-shadow: 3px 3px 4px 1px rgb(78, 69, 78);
}

.quiz-main-container .option-btn:hover {
  background-color: var(--blue-col);
  color: white;
}

.quiz-main-container #next-button {
  cursor: pointer;
  padding: 10px;
  margin-top: 1rem;
  margin-left: 0.5rem;
  border: none;
  background-color: var(--blue-col);
  color: white;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  transition: 0.3s;
  width: 100px;
  position: relative;
  bottom: 15px;
}
.quiz-main-container #next-button:hover{
  background-color: #60bff5;
}

.quiz-main-container #next-button:active {
  box-shadow: none;
}



.quiz-main-container .result {
  width: 50vw;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--white-col);
  border-radius: 10px;
}

.quiz-main-container .result .show-score {
  font-size: 2rem;
}

.quiz-main-container .result .btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.quiz-main-container .result .btns button{
  transition: 0.3s;
  cursor: pointer;
}
.quiz-main-container .result .btns button:hover{
  background-color: #60bff5;
}

@media screen and (max-width: 800px) {
  .quiz-main-container{
    height: 65vh;
  }
  .quiz-main-container .quiz-wrapper{
    width: 85%;
  }
  .quiz-main-container .question{
    font-size: 1.1rem;
    width: 100%;
  }
  .quiz-main-container .result{
    width: 85%;
  }
  .quiz-main-container .result .show-score{
    width: 100%;
    font-size: 1.2rem;
  }
}