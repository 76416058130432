.landing-page {
    height: auto;
    width: 100%;
}


.first-section {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(to right, #239ce2, #51b4ee);
    color: var(--white-col);
}

.first-section .container{
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100%;
}

.first-section .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.first-section .left h1{
    font-size: 2.5rem;
}
.first-section .left p{
    text-align: justify;
    line-height: 25px;
    margin-top: 2rem;
}

.first-section .right {
    display: flex;
    align-items: center;
    justify-content: end;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
}
.first-section .right img{
    width: 500px;
    margin-left: 4rem;
}

@media screen and (max-width: 810px) {
    .first-section{
        height: auto;
    }
    .first-section .container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .first-section .container h1{
        font-size: 1.7rem;
    }
    .first-section .container p{
        font-size: 0.7rem;
        line-height: 20px;
    }
    .first-section .container img{
        width: 90%;
        margin: auto;
    }
}

.about {
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    background-image: linear-gradient(to right, #e1f2fc, white, #cdebfd);
}
.about h1{
    font-size: 2rem;
}
.about h1 span{
    color: var(--blue-col);
}
.about p{
    width: 50%;
    font-weight: bold;
}

@media screen and (max-width: 800px) {
    .about p{
        width: 80%;
        font-size: 0.7rem;
    }
}


.features {
    background-image: linear-gradient(to right, #239ce2, #51b4ee);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0;
}
.features .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    height: auto;
}
.features .section-title{
    margin-top: 2rem;
    color: var(--white-col);
    font-size: 2rem;
}

.features .item{
    width: 200px;
    height: 150px;
    background-color: snow;
    color: var(--black-col);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
}

.features .item .icon{
    font-size: 3rem;
    margin-bottom: 1rem;
} 

.features .item h1.head-font{
    font-size: 1.3rem;
}

.contributors {
    width: 100%;
    height: 100vh;
}

.contributors .section-title{
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 2rem;
    margin-top: 3rem;
}

.contributors .container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.3rem;
}

.contributors .container .item{
    width: 250px;
    height: 250px;
    margin-top: 2rem;
    border-radius: 50%;
}

.contributors .container .item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.contributors .container .item h3{
    width: 100%;
    text-align: center;
    border: 1px solid gray;
    padding: 1rem 0.3rem;
    border-radius: 10px;
    margin-top: 0.5rem;
}


@media screen and (max-width: 800px) {
    .contributors{
        height: auto;
        margin-bottom: 6rem;
    }
    .contributors .container{
        flex-direction: column;
    }
    .contributors .container .item{
        margin-bottom: 2rem;
    }
}
