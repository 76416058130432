.signin {
    width: 100%;
    height: auto;
    background-color: var(--gray-col);
    display: flex;
    align-items: center;
    justify-content: center;
}

.signin .container {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
    background-color: whitesmoke;
    height: 120vh;
    border: 5px solid var(--white-col);
    border-radius: 20px;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
}

.signin .container {
    padding: 0 2rem;
}

.signin .form {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signin .form form {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem 2rem;
    border-radius: 10px;
}

.signin .form .head-font {
    font-size: 2rem;
}

.signin .form .head-font span {
    font-size: 2rem;
    color: var(--blue-col);
}

.signin .form h5 {
    margin-bottom: 1rem;
}


.signin .form form input,
.signin .form form select,
.signin .form form textarea {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    margin: 0.3rem 0;
    color: gray;
    font-size: 1rem;
}

.signin .form form .blue-btn {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem;
}

.signin .form form a {
    text-decoration: none;
    color: var(--blue-col);
}

.signin .container .img {
    padding: 2rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.signin .container .img img {
    width: 100%;
    height: 100%;
    background-color: #8deeff;
    border-radius: 20px;
}


@media screen and (max-width: 1000px) {

    .signin .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
    }

    .signin .container {
        flex-direction: column-reverse;
        width: 100%;
    }

    .signin .container .form {
        height: 64vh;
        width: 100%;
    }

}