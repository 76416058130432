.my-jobs {
    background-color: var(--gray-col);
}

.my-jobs .job-search {
    height: 40vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: var(--white-col);
}

.my-jobs .job-search .head-font {
    font-size: 2rem;
}

.my-jobs .job-search .head-font span {
    color: var(--blue-col);
}

.my-jobs .job-search p {
    letter-spacing: 2px;
}

.my-jobs .job-search form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
}

.my-jobs .job-search form input {
    padding: 0.4rem 1rem;
    width: 60%;
    border: 1px solid rgb(201, 200, 200);
}

.my-jobs .job-search form button {
    padding: 0.6rem 2rem;
    border-radius: 0 2px 2px 0;
}

.my-jobs .container {
    padding: 2rem 0;
    background-color: var(--gray-col);
}

.my-jobs .container .all-jobs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.my-jobs .container .all-jobs .item {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid var(--gray-col);
    padding: 1rem;
    gap: 0.9rem;
    width: 300px;
    background-color: var(--white-col);
    border-radius: 30px 40px 50px 20px;
}

.my-jobs .container .all-jobs .item .head-font{
    text-transform: capitalize;
}

.my-jobs .container .all-jobs .item .avg-sal {
    color: var(--blue-col);
}

.my-jobs .container .all-jobs .item .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around
}

.my-jobs .container .all-jobs .item .btn {
    border-radius: 30px;
    text-decoration: none;
    color: var(--white-col);
}