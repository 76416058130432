.signup {
    width: 100%;
    height: 100vh;
    background-color: var(--gray-col);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
}

.signup .container{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
    background-color: whitesmoke;
    height: 100%;
    border: 5px solid var(--white-col);
    border-radius: 20px;
    position: relative;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
}

.signup .container .role-selector{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.signup .container .role-selector .selector{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 20px;
    transition: 0.3s;
}
.signup .container .role-selector .selector:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.signup .container .role-selector .selector img{
    width: 130px;
}
.signup .form {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signup .form form {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem 2rem;
    border-radius: 10px;
}

.signup .form .head-font {
    font-size: 2rem;
}

.signup .form .head-font span {
    font-size: 2rem;
    color: var(--blue-col);
}

.signup .form h5 {
    margin-bottom: 1rem;
}


.signup .form form input,
.signup .form form select,
.signup .form form textarea {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    margin: 0.3rem 0;
    color: gray;
    font-size: 1rem;
}

.signup .form form .blue-btn {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem;
}

.signup .form form a {
    text-decoration: none;
    color: var(--blue-col);
}

.signup .container .img {
    padding: 2rem;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.signup .container .img img {
    width: 100%;
    height: 100%;
    background-color: #8deeff;
    border-radius: 20px;
}


@media screen and (max-width: 1000px) {
    .signup{
        height: auto;
        padding: 2rem 0;
    }
    .signup .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        height: 130vh;
    }
    .signup .container .role-selector{
        flex-direction: row;
        flex-wrap: wrap;
    }
    .signup .container .role-selector .selector{
        height: 150px;
        width: 200px;
        font-size: 0.9rem;
    }
    .signup .container .role-selector .selector img{
        width: 50px;
    }

    .signup .container .form {
        height: 64vh;
        width: 100%;
        margin-top: 2rem;
    }

}

@media screen and (max-width: 550px){
    .signup .container{
        width: 100%;
    }
}