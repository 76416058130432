.company-profile-view {
    width: 100%;
}

.company-profile-view .container {
    width: 90%;
}

.company-profile-view .container .left {
    width: 100%;
    margin: auto;
}

.company-profile-view .container .left .top {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 70vh;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 2rem;
    background-color: var(--white-col);
}

.company-profile-view .container .left .top .bg-image {
    height: 50%;
    overflow: hidden;
}

.company-profile-view .container .left .top .bg-image img {
    width: 100%;
}

.company-profile-view .container .left .top .logo {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
    border: 8px solid var(--gray-col);
}

.company-profile-view .container .left .top .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.company-profile-view .container .left .top .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 3rem;
}

.company-profile-view .container .left .top .info .text {
    width: 300px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.company-profile-view .container .left .main {
    margin-top: 2rem;
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 2rem;
}

.company-profile-view .container .left .main .item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.7rem;
}

.company-profile-view .container .left .main .item h2 {
    margin-bottom: 1rem;
}

.company-profile-view .container .left .main .item p {
    margin-left: 1rem;
}

.company-profile-view .container .left .main .item p a {
    text-decoration: none;
    color: var(--black-col);
}

.company-profile-view .container .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 0 1rem;
}

.company-profile-view .container .right .edit {
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 60vh;
    gap: 1rem;
}

.company-profile-view .container .right .edit .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--white-col);
    height: 30vh;
    border-radius: 20px;
    padding: 1rem;
}

.company-profile-view .container .right .edit .item p {
    color: gray;
}

.company-profile-view .container .right .edit .item .edit-link {
    border: none;
    background-color: transparent;
    font-size: 2rem;
    color: var(--blue-col);
    text-decoration: none;
}

.company-profile-view .container .right .edit .item button {
    border: none;
    background-color: transparent;
    font-size: 2rem;
    color: var(--blue-col);
}

.company-profile-view .container .right .profile-contact {
    background-color: var(--white-col);
    border-radius: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    padding: 1rem;
    height: 20vh;
}

@media screen and (max-width: 800px) {
    .company-profile-view .container .left .top{
        height: 80vh;
    }
    .company-profile-view .container .left .top .bg-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .company-profile-view .container .left .top .info{
        margin-top: 2.5rem;
    }
    .company-profile-view .container .left .top .logo{
        width: 100px;
        height: 100px;
    }
}