.navbar {
    position: relative;
    background-color: var(--white-col);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10vh;
    padding: 0 2rem;
    z-index: 2;
}
a{
    text-decoration: none;
}
a:visited{
    color: var(--blue-col);
}

.navbar .logo {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

.navbar .logo img {
    width: 50px;
}

.navbar .links {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
}


.navbar .links a {
    text-decoration: none;
    color: var(--black-col);
    font-size: 0.9rem;
}

.navbar .user {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.navbar .user img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.navbar .bars {
    font-size: 1.5rem;
    display: none;
}

.toogle-menu {
    display: none;
}

@media screen and (max-width: 1150px) {

    .navbar .user,
    .navbar .links {
        display: none;
    }

    .navbar .bars {
        display: block;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .toogle-menu {
        position: absolute;
        top: 10vh;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        background-color: var(--white-col);
        transition: all 0.3s;
        z-index: 2;
        padding-bottom: 2rem;
    }

    .toogle-menu .links,
    .toogle-menu .user {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.5rem;
    }

    .toogle-menu.hide {
        top: -100vh;
    }
}
