.profile-form {
  display: flex;
  justify-content: space-between;
}

form {
  display: grid;
  grid-template-columns: 40% 60%;
}

.left-grid,
.right-grid {
  padding: 2rem;
}

.left-grid .avatar-display {
  overflow: hidden;
  height: 350px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 50%;
}

.left-grid .avatar-display img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

.status {
  display: grid;
  grid-template-columns: 40% 60%;
}
.status .label{
  display: flex;
  align-items: center;
  justify-content: center;
}
select {
  width: 100%;
  padding: 0.7rem 1rem;
  border: none;
  background-color: var(--white-col);
  margin-top: 0.2rem;
  margin-bottom: 1rem;
}

.basic-info input,
.address input,
.education input,
.projects input,
.internship input,
.work-experience input,
.achievements input {
  width: 100%;
  padding: 0.7rem 1rem;
  border: none;
  background-color: var(--white-col);
  margin-bottom: 1rem;
}

input[type="file"] {
  margin-bottom: 1rem;
  width: 100%;
  background-color: var(--white-col);
  border: none;
}

.section-title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  letter-spacing: 2px;
}

.section-subtitle {
  margin-bottom: 1.5rem;
  margin-left: 1rem;
}

.basic-info input {
  margin-top: 0.1rem;
}

.achievements button[type=button] {
  margin-bottom: 3rem;
}

button[type=submit] {
  margin-top: 2rem;
  float: right;
}

button.add-new-btn {
  border-radius: 30px;
  border: 2px solid var(--blue-col);
  color: var(--blue-col);
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
}

button.add-new-btn .icon {
  font-size: 1.1rem;
}

button.add-new-btn:hover {
  background-color: var(--blue-col);
  color: var(--white-col);
}

@media screen and (max-width: 800px) {
  .profile-form form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}