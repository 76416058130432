.company-dashboard {
    height: 100%;
    width: 100%;
    background-color: var(--gray-col);
}

.company-dashboard .box-container {
    padding: 2rem;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3.5rem;
}

.company-dashboard .box-container .box {
    width: 240px;
    height: 220px;
    background-color: var(--white-col);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}


.company-dashboard .box-container .box p {
    color: rgb(105, 105, 105);
}

.company-dashboard .box-container .box h1 {
    color: var(--blue-col);
}

.company-dashboard .box-container .box:nth-child(1) {
    width: 500px;
}

.table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 2rem;
}

.table-container .table {
    padding: 1rem;
    background-color: var(--white-col);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: scroll;

}
.table-container .table.table-1{
    margin-right: 1.7rem;
    height: 34vh;
}
.table-container .table.table-2{
    margin-left: 1.7rem;
    height: 34vh;
}


.table-container .table::-webkit-scrollbar {
    width: 2px;
}

.table-container .table::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

table {
    border-collapse: collapse;
    width: 100%;
    text-transform: capitalize;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--blue-col);
    color: white;
}

@media screen and (max-width: 800px) {
    .company-dashboard .box-container{
        height: auto;
    }

    .company-dashboard .table-container{
        flex-direction: column;
        overflow: scroll;
        gap: 2rem;
        margin-left: 50px;
    }
    .company-dashboard .table-container .table.table-2{
        width: 300px;
        margin-left: 0px;
    }
    .company-dashboard .table-container .table.table-2::-webkit-scrollbar {
        height: 2px;
      }
      
}