.post-job {
    width: 100%;
    background-color: var(--white-col);
}

.post-job .container {
    background-color: var(--gray-col);
    padding: 2rem 0;
}

.post-job .container form {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.post-job .container form input,
.post-job .container form select,
.post-job .container form textarea {
    width: 100%;
    padding: 0.7rem 1rem;
    border: none;
    background-color: var(--white-col);
}

.post-job .container form label {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
}

.post-job .container form .top {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
}

.post-job .container form .top .part-1,
.post-job .container form .top .part-2,
.post-job .container form .bottom {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    width: 100%;
}

@media screen and (max-width:850px) {
    .post-job .container form .top{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
    }
}