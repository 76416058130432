.admin-login{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-login .admin-login-form{
    padding: 2rem;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    background-color: var(--white-col);
    border-radius: 20px;
}
.admin-login form h2{
    margin-bottom: 2rem;
}
.admin-login form input{
    width: 60%;
    margin-bottom: 1.5rem;
}

@media screen and (max-width: 800px) {
    .admin-login .admin-login-form{
        width: 90%;
    }
}