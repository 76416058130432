
.container {
    min-width: 550px;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    padding-top: 5px;
    background-color: whitesmoke;
}

.header {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.header .section{
    padding: 10px;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    font-size: 1rem;
    white-space:  nowrap;
    cursor: pointer;
}

.header .active{
    border-bottom: 2px solid #239ce2;
    color: #239ce2;
}

.body{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 0;
}

.detail{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detail .row{
    display: flex;
    gap: 20px;
}

.detail .column{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.detail .row > div {
    flex: 1;
}

.chips{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.chip{
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 4px 8px;
    border-radius: 20px;
}

.chip p{
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff;
}

.chip svg{
    color: #fff;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.chip .active{
    background-color: #239ce2;
}

.body button{
    width: fit-content;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #239ce2;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition: 200ms;
}

.body button:active{
    transform: translateY(2px);
}.container {
    min-width: 550px;
    min-height: 450px;
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    height: fit-content;
    margin: 0 auto;
  }
  
  .header {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .header::-webkit-scrollbar {
    height: 7px;
  }
  
  .header::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #c7c7c7;
  }
  
  .header .section {
    padding: 10px;
    border-bottom: 2px solid transparent;
    font-weight: 500;
    font-size: 1rem;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .header .active {
    border-bottom: 2px solid #239ce2;
    color: #239ce2;
  }
  
  .body {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 0;
  }
  
  .detail {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .detail .row {
    display: flex;
    gap: 20px;
  }
  .detail .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .detail .row > div {
    flex: 1;
  }
  
  .chips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  
  .chips .new {
    color: #239ce2;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
  }
  
  .chip {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 4px 8px;
    background-color: #808080;
    border-radius: 20px;
    cursor: default;
  }
  
  .chip p {
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff;
  }
  .chip svg {
    color: #fff;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
  
  .chips .active {
    background-color: #239ce2;
  }
  
  .body button {
    width: fit-content;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #239ce2;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 1px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition: 200ms;
  }
  
  .body button:active {
    transform: translateY(2px);
  }

  @media screen and (max-width:800px){
    .header{
      padding-right: 30px;
    }
  }