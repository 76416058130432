.admin-dashboard {
    height: 100%;
    width: 100%;
    background-color: var(--gray-col);
}

.admin-dashboard .box-container {
    padding: 2rem;
    width: 100%;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
}

.admin-dashboard .box-container .box {
    width: 320px;
    height: 170px;
    background-color: var(--white-col);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.admin-dashboard .box-container .box p {
    color: rgb(105, 105, 105);
}

.admin-dashboard .box-container .box h1 {
    color: var(--blue-col);
}

.table-container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
}

.table-container .table {
    padding: 1rem;
    background-color: var(--white-col);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: relative;
    overflow: scroll;
    height: 40vh;
}

.table-container .table::-webkit-scrollbar{
    width: 2px;
}
.table-container .table::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 10px;
}

table {
    border-collapse: collapse;
    width: 100%;
    
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--blue-col);
    color: white;
}

@media screen and (max-width: 800px) {
    .admin-dashboard .box-container{
        flex-direction: column;
        height: auto;
    }
    .admin-dashboard .table-container{
        flex-direction: column;
        height: auto;
    }
    .admin-dashboard .table-container .table{
        width: 90%;
        margin: auto;
    }
}