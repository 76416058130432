.container {
  padding: 50px 30px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  background-image: linear-gradient(45deg, rgb(147, 235, 255), rgb(197, 241, 251));
  height: 100vh;
}

.heading {
  margin: 0 auto;
  max-width: 500px;
  color: #000;
  font-size: 3.1rem;
  line-height: 4.1rem;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  letter-spacing: 3px;
}

.heading span {
  background-clip: text;
  background-image: linear-gradient(to right, #239ce2, #1369b9);
  color: transparent;
}

.right img {
  width: 400px;
}
@media screen and (max-width:800px){
  .container{
    display: flex;
    flex-direction: column;
  }
  .right img{
    width: 300px;
  }
  .heading{
    font-size: 2rem;
  }
}